import React from "react"
import Layout from "../components/layout"
import Articles from "../components/Articles"
import { graphql } from "gatsby"
import Seo from "../components/seo"

const Blog = ({ data }) => {
  return (
    <>
      <Layout>
        <Seo title="Blog" />
        <Articles data={data} />
      </Layout>
    </>
  )
}

export default Blog

export const query = graphql`
  query ArticlesQuery {
    allDatoCmsArticle {
      edges {
        node {
          slug
          title
          creationDate
          articleContentNode {
            childMarkdownRemark {
              excerpt
            }
            id
          }
          articleImage {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`
