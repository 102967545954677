import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const ArticleCard = props => {
  const { image, alt, title, intro, slug } = props
  return (
    <ArticleCardWrapper>
      <div className="article-hero">
        <a href={`/blog/${slug}`}>Czytaj dalej...</a>
      </div>
      <ArticleImg image={image} alt={alt ? alt : "Article Card Image"} />
      <TextWrap className="article-text-wrapper">
        <ArticleTitle>{title}</ArticleTitle>
        <ArticleIntro>{intro}</ArticleIntro>
      </TextWrap>
    </ArticleCardWrapper>
  )
}

export default ArticleCard

const ArticleCardWrapper = styled.div`
  position: relative;
  transition: 0.2s ease;
  margin-bottom: 5px;
  font-family: "Gentium Book Basic";
  &:nth-child(1) {
    grid-area: 1 / 1 / 3 / 3;
  }

  & div.article-hero {
    position: absolute;
    width: 100%;
    height: 60%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.2s;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: #fff;
      width: 150px;
      height: 70px;
      background-color: transparent;
      border: 2px solid white;
      border-radius: 12px;
      cursor: pointer;
      transition: 0.3s all;
      text-align: center;
      box-shadow: 0px 0px 5px white;
      font-style: italic;
      font-size: 1.2rem;
    }
    a:hover {
      box-shadow: 1px 1px 5px #fff;
      transform: scale(1.05);
    }
  }

  &:hover div.article-hero {
    opacity: 1;
  }
  & div.article-text-wrapper {
    transition: 0.2s;
  }
  &:hover div.article-text-wrapper {
    background-color: rgba(0, 0, 0, 0.9);
  }
`

const ArticleImg = styled(GatsbyImage)`
  height: 100%;
  max-width: 100%;
  position: relative;
`
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  height: 40%;
  width: fill-available;
  word-break: break-word;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  text-shadow: 1px 1px 2px #aaa;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #bbb;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
`
const ArticleTitle = styled.h2`
  padding: 10px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  font-style: italic;
`
const ArticleIntro = styled.p`
  padding: 10px;
  font-weight: 400;
  word-break: break-word;
  overflow-wrap: break-word;
  text-align: left;
  width: 100%;
  text-indent: 20px;
`
