import React from "react"
import styled from "styled-components"
import { getImage } from "gatsby-plugin-image"
import ArticleCard from "./ArticleCard"

const Articles = ({ data }) => {
  const getArticles = data => {
    const displayArray = []
    data.allDatoCmsArticle.edges.reverse().forEach(item => {
      const slug = item.node.slug
      const image = getImage(item.node.articleImage)
      const alt = item.node.articleImage.alt
      const id = item.node.articleContentNode.id
      const title = item.node.title
      const intro = item.node.articleContentNode.childMarkdownRemark.excerpt
      displayArray.push(
        <ArticleCard
          key={id}
          image={image}
          alt={alt}
          title={title}
          intro={intro}
          slug={slug}
        />
      )
    })
    return displayArray
  }

  return (
    <ArticlesContainer>
      <ArticlesWrapper>{getArticles(data)}</ArticlesWrapper>
    </ArticlesContainer>
  )
}

export default Articles

const ArticlesContainer = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  min-height: calc(100vh - 81px - 5vh);

  @media screen and (max-width: 992px) {
    padding: 10px;
  }
`

const ArticlesWrapper = styled.div`
  margin-top: 10px;
  display: grid;
  width: 86vw;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  justify-items: center;
  align-self: center;

  @media screen and (max-width: 1700px) {
    width: 95vw;
  }
  @media screen and (max-width: 1400px) {
    width: 90vw;
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1078px) {
    width: 90vw;
    grid-template-columns: repeat(2, 1fr);
    & div:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
